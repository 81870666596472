


























import './styles/ProjectRow.scss'
import './styles/ProjectRowAdaptive.scss'
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IUserProject } from '@store/modules/user-projects/Interfaces';
import { userNamespace } from '@store/modules/user';
import { IUserState } from '@store/modules/user/Interfaces';
import { WorkListMutations } from '@store/modules/work-list/Types';
import { namespace } from 'vuex-class';
const NSWorkList = namespace('storeWorkList');

@Component({
   name: 'ProjectRow',

   components: {
       BaseButton: () => import('@components/buttons/BaseButton.vue'),
   },
})
export default class ProjectRow extends Vue {
    @Prop({}) project!: IUserProject;
    @userNamespace.Getter('userData') userData!: IUserState;
    @NSWorkList.Mutation(WorkListMutations.M_UNSET_CONSTRUCT_PACKS) unsetConstructPacks;

    get portalPartAccess() {
        return this.userData.userInfo && this.userData.userInfo.portalPart;
    }

    goToProject() {
        this.$router.push({
            name: 'project-documents',
            params: { projectId: String(this.project.id) }
        });
        this.unsetConstructPacks();
    }
}
